import { ReactNode, useLayoutEffect, useRef } from 'react';

interface SlideBlockProps {
	open: boolean;
	children: ReactNode;
}

function SlideBlock({ open, children }: SlideBlockProps) {
	const container = useRef<HTMLDivElement | null>(null);
	const inited = useRef(false);

	useLayoutEffect(() => {
		if (!container.current) return;

		const el = container.current;

		el.style.overflow = 'hidden';

		if (!inited.current) {
			if (!open) el.style.height = '0px';
			inited.current = true;
			return;
		}

		el.style.height = el.offsetHeight + 'px';

		setTimeout(() => {
			if (!container.current) return;
			const el = container.current;
			if (open) {
				el.style.height = el.scrollHeight + 'px';

				const cb = () => {
					if (!container.current) return;
					const el = container.current;
					el.style.height = '';
					el.style.overflow = '';
					el.removeEventListener('transitionend', cb);
				};
				el.addEventListener('transitionend', cb);
			} else {
				el.style.height = `0px`;
			}

			if (!el.style.transition) {
				el.style.transition = 'height 400ms ease-in-out';
			}
		});
	}, [open]);

	return <div ref={container}>{children}</div>;
}

export type { SlideBlockProps };
export { SlideBlock };
