import {
	SyntheticEvent,
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react';
import styled from 'styled-components';
import { DEVICE } from '../../constants/breakpoints';

type Tab = {
	id: string;
	text: string;
};

interface TabButtonProps {
	tab: Tab;
	isActive: boolean;
}

interface TabsProps {
	items: Array<Tab>;
	active: string;
	onChange: (newActive: string) => void;
}

interface TabStyleProps {
	isActive: boolean;
}

interface MarkerStyleProps {
	top: number;
	left: number;
	width: number;
}

const TabStyle = styled.button<TabStyleProps>`
	background: transparent;
	border: none;
	outline: none;
	font: inherit;

	padding: 0.5em;
	cursor: pointer;
	user-select: none;
	color: inherit;
	text-transform: uppercase;
	font-weight: 700;
	font-size: inherit;
	margin-bottom: 0.5rem;

	opacity: ${(props) => (props.isActive ? 1 : 0.6)};
	transition: opacity 250ms ease-in-out;

	&:focus,
	&:active {
		outline: none;
		background: transparent;
	}

	&:not(:last-child) {
		margin-right: 1rem;
	}

	@media ${DEVICE.mobileMdDown} {
		padding: 0.5em 0.25em;
	}
`;
const TabsStyle = styled.div`
	display: flex;
	flex-wrap: wrap;
	position: relative;
	font-size: 1rem;
	margin-left: -0.5em;
	margin-right: -0.5em;

	@media ${DEVICE.mobileLgDown} {
		font-size: 0.8rem;
	}
`;
const MarkerStyle = styled.div<MarkerStyleProps>`
	position: absolute;
	height: 4px;
	background: currentColor;
	top: ${(props) => props.top}px;
	left: ${(props) => props.left}px;
	width: ${(props) => props.width}px;
	border-radius: 4px;

	transition: all 250ms ease-in-out;
`;

function getMarkerDimensions(container: HTMLElement | null, active: string) {
	const newDimensions = {
		left: 0,
		top: 0,
		width: 0,
	};
	if (active && container) {
		const activeItem = container.querySelector(`[data-item="${active}"]`);
		if (activeItem) {
			const rect = activeItem.getBoundingClientRect();
			const containerRect = container.getBoundingClientRect();
			newDimensions.top = rect.bottom - containerRect.top;
			newDimensions.left = rect.left - containerRect.left;
			newDimensions.width = rect.width;
		}
	}
	return newDimensions;
}

const mql = window.matchMedia(DEVICE.mobileLgDown);

function Tabs({ items, active, onChange }: TabsProps) {
	const element = useRef<HTMLDivElement | null>(null);
	const [markerDimensions, setMarkerDimensions] = useState({
		left: 0,
		top: 0,
		width: 0,
	});

	const onItemClick = useCallback(
		(e: SyntheticEvent<HTMLButtonElement>) => {
			if (!(e.target instanceof HTMLButtonElement)) return;
			const { item } = e.target.dataset;
			if (item) onChange(item);
		},
		[onChange]
	);

	useEffect(() => {
		const cb = () => {
			const dimensions = getMarkerDimensions(element.current, active);
			setMarkerDimensions(dimensions);
		};

		cb();

		mql.addEventListener('change', cb);
		return () => mql.removeEventListener('resize', cb);
	}, [element, active]);

	return (
		<TabsStyle ref={element}>
			{items.map((item) => (
				<TabStyle
					key={item.id}
					type='button'
					data-item={item.id}
					isActive={active === item.id}
					onClick={onItemClick}
				>
					{item.text}
				</TabStyle>
			))}

			{active && <MarkerStyle {...markerDimensions} />}
		</TabsStyle>
	);
}

export type { Tab, TabsProps };
export { Tabs };
