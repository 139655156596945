import styled from 'styled-components';

const Container = styled.div`
	width: 100%;
	max-width: 1000px;
	margin-left: auto;
	margin-right: auto;
	padding-left: 1rem;
	padding-right: 1rem;
`;

export { Container };
