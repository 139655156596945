const Articles = [
  {
    title:
      "Распространенные алгоритмы и структуры данных в JavaScript: стеки, очереди и связные списки",
    href: "https://proglib.io/p/rasprostranennye-algoritmy-i-struktury-dannyh-v-javascript-steki-ocheredi-i-svyaznye-spiski-2021-10-13",
  },
  {
    title: "Как не нужно учить TypeScript: 5 распространенных ошибок",
    href: "https://proglib.io/p/kak-ne-nuzhno-uchit-typescript-5-rasprostranennyh-oshibok-2022-01-22",
  },
  {
    href: "https://proglib.io/p/redux-i-vuex-pravaya-i-levaya-palochki-twix-2019-12-07",
    title: "Redux и Vuex: правая и левая палочки Twix",
  },
  {
    href: "https://proglib.io/p/vlastelin-konsoli-poznay-tayny-debagginga-2019-09-12",
    title: "Властелин консоли: познай тайны дебаггинга",
  },
];

export default Articles;
