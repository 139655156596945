
import styled from "styled-components";

const Label = styled.label`
  display: block;
  margin: 1em 0;
  font-weight: 700;

  small {
    font-size: 0.875em;
    font-weight: 500;
  }
`;


export { Label }