import styled from "styled-components";
import { DEVICE } from "../../constants/breakpoints";
import { Button } from "../../containers/Button";
import { Space } from "../../containers/Space";

import { BigProject } from "../../types";
import { Carousel } from "../Carousel";

interface ProjectViewProps {
  project: BigProject;
}

const ProjectStyle = styled.article`
  h3 {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  }

  b {
    font-weight: 700;
  }

  ${Button} {
    @media ${DEVICE.mobileMdDown} {
      width: 100%;
    }
  }
`;

const FooterStyle = styled.footer`
  margin-top: 2rem;
  display: flex;
  justify-content: center;
`;

function ProjectView({ project }: ProjectViewProps) {
  return (
    <ProjectStyle>
      <h3>{project.title}</h3>

      <Space bottom="1rem">{project.description}</Space>

      <Space bottom="1rem">
        {project.technologies.map((tech) => (
          <Space key={tech.title} bottom="1rem">
            <i>{tech.title}:</i> {tech.text}
          </Space>
        ))}
      </Space>

      {project.slider.length > 0 && (
        <Space bottom="1rem">
          <Carousel slides={project.slider}></Carousel>
        </Space>
      )}

      <FooterStyle>
        <Button
          href={project.link}
          target="_blank"
          rel="noopener noreferrer nofollow"
          lg
        >
          Перейти на сайт
        </Button>
      </FooterStyle>
    </ProjectStyle>
  );
}

export type { ProjectViewProps };
export { ProjectView };
