import styled from 'styled-components';

import sprite from '../../img/sprite.png'

type Social = 'vk' | 'github' | 'tg' | 'email';

interface LinkStyleProps {
	social: Social;
}

const bgPositions = {
  vk: -6,
  tg: -66,
  github: -186,
  email: -126
}

const LinkStyle = styled.a<LinkStyleProps>`
  width: 60px;
  height: 60px;
  display: block;
  text-decoration: none;
  margin: 0 0.1rem;
  background-image: url(${sprite});
  background-repeat: no-repeat;
  background-size: auto 60px;
  background-position: ${props => bgPositions[props.social]}px 0;
  transition: transform 400ms;

  &:hover {
    transform: scale(1.4);
  }
`;

const WrapperStyle = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;

const SOCIALS: Array<{
	href: string;
	social: Social;
	target: string;
  title: string;
}> = [
	{
		href: 'https://vk.com/furry_cat',
		social: 'vk',
		target: '_blank',
    title: 'ВКонтакте'
	},
	{
		href: 'https://github.com/Mohnatus',
		social: 'github',
		target: '_blank',
    title: 'GitHub'
	},
	{
		href: 'https://t.me/Meeeowth',
		social: 'tg',
		target: '_top',
    title: 'Telegram'
	},
	{
		href: 'mailto:furrycat.web@gmail.com',
		social: 'email',
		target: '_top',
    title: 'Email'
	},
];

function Footer() {
	return (
		<WrapperStyle>
			{SOCIALS.map((link) => (
				<LinkStyle
					key={link.social}
					href={link.href}
					target={link.target}
					rel='noopener noreferrer'
					social={link.social}
          title={link.title}
				/>
			))}
		</WrapperStyle>
	);
}

export { Footer };
