import { BigProject } from "../types";

const ProglibProject: BigProject = {
  enabled: true,
  id: "proglib",
  name: "Proglib.io",
  link: "https://proglib.io",
  title: "Портал Библиотека программиста",
  description:
    "Разработка с нуля клиентской части и административного раздела сайта Библиотека программиста",
  technologies: [
    {
      title: "Верстка",
      text: "шаблонизатор Twig, HTML, SVG, адаптивная верстка",
    },
    {
      title: "Стили",
      text: "препроцессор SCSS, БЭМ",
    },
    {
      title: "Клиентская логика",
      text: "ES6, ReactJS, Babel",
    },
    {
      title: "Сборка проекта",
      text: "Webpack",
    },
    {
      title: "Передача/получение данных",
      text: "AJAX, REST, websockets",
    },
    {
      title: "Контроль версий",
      text: "Git",
    },
  ],
  slider: [
    {
      img: "/assets/proglib/1.png",
      text: "Главная страница",
      alt: "Скриншот главной страницы сайта proglib.io",
    },
    {
      img: "/assets/proglib/2.png",
      text: "Профиль пользователя",
      alt: "Скриншот профиля пользователя на сайте proglib.io",
    },
    {
      img: "/assets/proglib/3.png",
      text: "Фид вакансий",
      alt: "Скриншот страницы с фидом вакансий на сайте proglib.io",
    },
    {
      img: "/assets/proglib/4.png",
      text: "Тест",
      alt: "Скриншот страницы интерактивного теста на сайте proglib.io",
    },
    {
      img: "/assets/proglib/5.png",
      text: "Комментарии",
      alt: "Скриншот формы и фида комментариев на сайте proglib.io",
    },
    {
      img: "/assets/proglib/6.png",
      text: "Редактор статей",
      alt: "Скриншот редактора статей на сайте proglib.io",
    },
    {
      img: "/assets/proglib/7.png",
      text: "Форма создания мероприятия",
      alt: "Скриншот формы создания мероприятия на сайте proglib.io",
    },
  ],
};

const FoquzProject: BigProject = {
  enabled: true,
  id: "foquz",
  name: "Foquz.ru",
  link: "https://foquz.ru",
  title:
    "Автоматизированная система для сбора обратной связи и управления клиентским опытом Foquz",
  description:
    "Разработка личного кабинета сервиса, интерфейса для создания и прохождения опросов, динамических виджетов для сторонних сайтов. Сложная клиентская логика, большое количество таблиц, графиков, отчетов.",
  technologies: [
    {
      title: "Верстка",
      text: "HTML, SVG, адаптивная верстка",
    },
    {
      title: "Стили",
      text: "препроцессор LESS, БЭМ",
    },
    {
      title: "Клиентская логика",
      text: "ES6, Babel, KnockoutJS, TypeScript",
    },
    {
      title: "Сборка проекта",
      text: "Webpack",
    },
    {
      title: "Передача/получение данных",
      text: "AJAX, REST",
    },
    {
      title: "Контроль версий",
      text: "Git",
    },
  ],
  slider: [],
};

const ChitaiGorodProject: BigProject = {
  enabled: true,
  id: "chg",
  name: "Chitai-gorod.ru",
  link: "https://chitai-gorod.ru",
  title: "Книжный интернет-магазин Читай-город",
  description:
    "Разработка сайта, личного кабинета покупателя, корзины, полный редизайн сайта с переписыванием части функционала с нуля. Создание множества посадочных страниц (в т. ч. интерактивных) для акций и мероприятий.",
  technologies: [
    {
      title: "Верстка",
      text: "HTML, SVG, адаптивная верстка",
    },
    {
      title: "Стили",
      text: "CSS, БЭМ",
    },
    {
      title: "Клиентская логика",
      text: "JavaScript",
    },
    {
      title: "Передача/получение данных",
      text: "AJAX, REST",
    },
    {
      title: "Контроль версий",
      text: "Git",
    },
    {
      title: "CMS",
      text: "Bitrix",
    },
  ],
  slider: [
    {
      img: "/assets/chitai-gorod/1.png",
      text: "Главная страница",
      alt: "Скриншот главной страницы сайта chitai-gorod.ru",
    },
    {
      img: "/assets/chitai-gorod/2.png",
      text: "Корзина",
      alt: "Скриншот корзины на сайте chitai-gorod.ru",
    },
    {
      img: "/assets/chitai-gorod/3.png",
      text: "Доставка",
      alt: "Скриншот страницы доставки на сайте chitai-gorod.ru",
    },
    {
      img: "/assets/chitai-gorod/4.png",
      text: "Личный кабинет пользователя",
      alt: "Скриншот личного кабинета пользователя на сайте chitai-gorod.ru",
    },
  ],
};

const list = [ProglibProject, FoquzProject, ChitaiGorodProject];

export default list;
