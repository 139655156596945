export const enum ProjectTypes {
  Landing = "landing",
  Interactive = "interactive",
}

export type Project = {
  enabled: boolean;
  type: ProjectTypes;
  id: string;
  name: string;
  description: string;
  adaptive: boolean;
  interactive?: boolean;
  keywords: Array<string>;
  year: number;
  company?: string;
  technologies: Array<string>;
  code?: boolean;
  link?: string;
  github?: string;
  rating: number;
};

export type BigProject = {
  enabled: boolean;
  id: string;
  name: string;
  link: string;
  title: string;
  description: string;
  technologies: Array<{
    title: string;
    text: string;
  }>;
  slider: Array<{
    img: string;
    text: string;
    alt: string;
  }>;
};
