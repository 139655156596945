import { useState, FormEvent, ChangeEvent, useEffect } from 'react';
import styled from 'styled-components';
import { DEVICE } from '../../constants/breakpoints';
import { COLORS } from '../../constants/colors';
import { Button } from '../../containers/Button';
import { Spinner } from '../../containers/Spinner';
import { Feedback, FeedbackMessage } from './Feedback';
import { FileLoader } from './FileLoader';
import { FormField } from './FormField';
import { Label } from './Label';
import { TextField } from './TextField';

const FooterStyle = styled.footer`
	margin-top: 2rem;
	text-align: center;
`;
const SubmitStyle = styled(Button)`
	position: relative;

	@media ${DEVICE.mobileMdDown} {
		width: 100%;
	}
`;
const PendingStyle = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border-radius: inherit;
	background: rgba(255, 255, 255, 0.2);
	backdrop-filter: blur(2px);
	display: flex;
	align-items: center;
	justify-content: center;
	color: ${COLORS.accent};
`;

function Form() {
	const [touched, setTouched] = useState(false);
	const [pending, setPending] = useState(false);
	const [feedback, setFeedback] = useState<Feedback | null>(null);
	const [contacts, setContacts] = useState('');
	const [order, setOrder] = useState('');
	const [files, setFiles] = useState<File[]>([]);

	useEffect(() => {
		if (feedback) setTimeout(() => setFeedback(null), 3000);
	}, [feedback, setFeedback]);

	const onSubmit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		setTouched(true);

		console.log('submit', { contacts, order });

		if (pending) return;

		if (!contacts || !order) return false;

		setPending(true);

		const fd = new FormData();
		fd.append('contact', contacts);
		fd.append('order', order);
		files.forEach((file) => fd.append('files[]', file));

		fetch('./form.php', {
			method: 'POST',
			body: fd,
		})
			.then((response) => {
				if (!response.ok) {
					return Promise.reject(response);
				}
				return response.json();
			})
			.then(() => {
				setTouched(false);
				setFeedback({
					success: true,
					message: 'Заявка отправлена',
				});
			})
			.catch(() => {
				setFeedback({
					success: false,
					message: 'Не удалось отправить заявку :(',
				});
			})
			.finally(() => setPending(false));
	};

	return (
		<form
			onSubmit={onSubmit}
			action='form.php'
			method='POST'
			encType='multipart/form-data'
		>
			<div className='container'>
				<TextField
					touched={touched}
					value={contacts}
					name='contact'
					onChange={setContacts}
				>
					Как с вами связаться?{' '}
					<small>
						(почта, телеграм, телефон, социальные сети, ...)
					</small>
				</TextField>

				<TextField
					touched={touched}
					value={order}
					name='order'
					onChange={setOrder}
				>
					Детали заказа <small>(любая информация о заказе)</small>
				</TextField>

				<FormField>
					<Label>
						Файлы{' '}
						<small>
							(скрины, макеты, прототипы, не более 5 файлов,
							каждый не более 2 МБ)
						</small>
					</Label>

					<FileLoader
						files={files}
						onChange={setFiles}
						accept='image/*, video/*, audio/*, .pdf'
						max={5}
						maxSize={1024 * 1024 * 2}
					/>
				</FormField>
				<FooterStyle>
					<SubmitStyle
						as='button'
						type='submit'
						lg
						disabled={pending}
					>
						Отправить
						{pending && (
							<PendingStyle>
								<Spinner></Spinner>
							</PendingStyle>
						)}
					</SubmitStyle>

					{feedback && (
						<FeedbackMessage success={feedback.success}>
							{feedback.message}
						</FeedbackMessage>
					)}
				</FooterStyle>
			</div>
		</form>
	);
}

export { Form };
