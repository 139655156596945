import styled from "styled-components";
import { COLORS } from "../../constants/colors";

type Feedback = {
	success: boolean;
	message: string;
};

interface FeedbackMessageProps {
	success?: boolean;
}

const FeedbackMessage = styled.div<FeedbackMessageProps>`
	color: ${(props) => (props.success ? COLORS.success : COLORS.error)};
	margin-top: 0.5rem;
`;

export type { Feedback, FeedbackMessageProps}
export { FeedbackMessage };
