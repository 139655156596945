import { useState, useLayoutEffect } from "react";
import styled from "styled-components";
import { DEVICE } from "../../constants/breakpoints";
import { FONTS } from "../../constants/typography";

interface LayerStyleProps {
  offset: number;
  filter: number;
}

const useScroll = () => {
  const [scroll, setScroll] = useState(0);
  useLayoutEffect(() => {
    setScroll(window.scrollY);

    const cb = () => {
      setScroll(window.scrollY);
    };
    document.addEventListener("scroll", cb, { passive: true });
    return () => document.removeEventListener("scroll", cb);
  }, []);

  return scroll;
};

const HeroStyle = styled.div`
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1rem;
  text-shadow: 1px 0 6px black;
  font-family: ${FONTS.hero};

  h1 {
    font-size: 5em;
  }

  h2 {
    font-size: 2em;
  }

  @media ${DEVICE.mobileLgDown} {
    font-size: 0.75rem;
  }

  @media ${DEVICE.mobileSmDown} {
    font-size: 0.5rem;
  }
`;

const LayerStyle = styled.div<LayerStyleProps>`
  position: fixed;
  transform: translateY(-${(props) => props.offset}px);
  filter: brightness(${(props) => props.filter});
`;

function Hero() {
  const scroll = useScroll();

  const offset = scroll / 1.85 - 60;

  const filter = Math.max(0.4, 1 - (scroll / 100) * 0.05);

  return (
    <HeroStyle className="header screen full-height">
      <LayerStyle offset={offset} filter={filter}>
        <h1>FurryCat</h1>
        <h2>FRONTEND&bull;DEVELOPER</h2>
      </LayerStyle>
    </HeroStyle>
  );
}

export { Hero };
