import { ChangeEvent, ReactNode } from "react";
import styled from "styled-components";
import { Feedback, FeedbackMessage } from "./Feedback";
import { FormField } from "./FormField";
import { Label } from "./Label";
import TextareaAutosize from 'react-textarea-autosize'

const TextareaStyle = styled(TextareaAutosize)`
  display: block;
  width: 100%;
  padding: 1rem;
  background: hsla(0, 0%, 100%, 0.5);
  color: inherit;
  font: inherit;
  border: none;
  outline: none;
  border-radius: 4px;
  resize: none;
`;

interface TextFieldProps {
  value: string;
  name: string;
  touched: boolean;
  onChange: (newValue: string) => void;
  children: ReactNode
}

function TextField({ value, name, touched, onChange, children  }: TextFieldProps) {
  const onInput = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    onChange(value);
  };

  return (
    <FormField>
      <Label htmlFor={name}>
        { children }
      </Label>
      <TextareaStyle
        id={name}
        name={name}
        value={value}
        onInput={onInput}
        minRows={5}
      ></TextareaStyle>

      {touched && !value && <FeedbackMessage>Обязательное поле</FeedbackMessage>}
    </FormField>
  );
}

export type { TextFieldProps };
export { TextField };
