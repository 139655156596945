import { useState } from "react";
import styled from "styled-components";

import { Container } from "../../containers/Container";
import Projects from "../../data/projects";
import { Tabs } from "../Tabs";
import { ProjectCard } from "./ProjectCard";
import { Project } from "../../types";
import { Space } from "../../containers/Space";
import { DEVICE } from "../../constants/breakpoints";

const ListStyle = styled(Space)`
  display: flex;
  flex-wrap: wrap;
`;

const ItemStyle = styled(Space)`
  width: 33.33%;

  @media ${DEVICE.mobileLgDown} {
    width: 50%;
  }

  @media ${DEVICE.mobileMdDown} {
    width: 100%;
    max-width: 400px;
    margin: auto;
  }
`;

const ProjectsList: Array<Project> = Projects.filter(
  (project) => project.enabled
);
ProjectsList.sort((a, b) => {
  if (a.rating === b.rating) return b.year - a.year;
  return b.rating - a.rating;
});

const ProjectTypes = [
  {
    id: "all",
    text: "Все проекты",
  },
  {
    id: "landing",
    text: "Landing",
    type: "type",
  },
  {
    id: "interactive",
    text: "Interactive",
    type: "type",
  },
  {
    id: "react",
    text: "React",
    type: "technology",
  },
];

function SmallProjects() {
  const [type, setType] = useState("all");

  let list = ProjectsList;

  const filter = ProjectTypes.find((t) => t.id === type);
  if (filter) {
    if (filter.type === "type") {
      list = list.filter((project) => project.type === type);
    } else if (filter.type === "technology") {
      list = list.filter((project) => project.technologies.includes(type));
    }
  }

  return (
    <div>
      <Space bottom="2rem">
        <Tabs items={ProjectTypes} active={type} onChange={setType}></Tabs>
      </Space>

      <ListStyle margin x="-0.5rem" bottom="-1rem">
        {list.map((project) => (
          <ItemStyle key={project.id} x="0.5rem" bottom="1rem">
            <ProjectCard project={project} />
          </ItemStyle>
        ))}
      </ListStyle>
    </div>
  );
}

export { SmallProjects };
