import { useCallback, useState } from "react";
import styled from "styled-components";
import { Space } from "../../containers/Space";

import AboutData from "../../data/about";
import Articles from "../../data/articles";
import { SlideBlock } from "./SlideBlock";

interface TechGroupProps {
  title: string;
  items: Array<string>;
}

const AboutWrapperStyle = styled.div`
  a {
    color: inherit;
	font-weight: 700;
  }
`;

const WrapperStyle = styled.div`
  padding: 5px 0;
`;
const TitleStyle = styled.div`
  border-bottom: 2px solid;
  padding: 0.5rem 30px 0.5rem 1rem;
  position: relative;
  cursor: pointer;

  &:after {
    content: "+";
    position: absolute;
    right: 10px;
    bottom: 0;
    top: 0;
    margin: auto;
    font-weight: 700;
  }
`;

const ListStyle = styled.ul`
  list-style: inside;

  li {
    margin: 0.7em 0;
  }
`;

function TechGroup({ title, items }: TechGroupProps) {
  const [open, setOpen] = useState(false);
  const toggle = useCallback(() => {
    setOpen((prev) => !prev);
  }, [setOpen]);
  return (
    <WrapperStyle>
      <TitleStyle onClick={toggle}>{title}</TitleStyle>
      <SlideBlock open={open}>
        <Space x="1rem" y="1.5rem">
          <ListStyle>
            {items.map((skill, j) => (
              <li key={j}>{skill}</li>
            ))}
          </ListStyle>
        </Space>
      </SlideBlock>
    </WrapperStyle>
  );
}

function About() {
  return (
    <AboutWrapperStyle>
      <Space bottom="2rem">
        Занимаюсь фронтендом с {AboutData.start.year} года.
      </Space>

      <Space bottom="2rem">
        Пишу и перевожу статьи по теме Фронтенд-разработка:
        <ListStyle>
          {Articles.map(
            (article: { href: string; title: string }, i: number) => (
              <li key={i}>
                <a
                  href={article.href}
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  {article.title}
                </a>
              </li>
            )
          )}
        </ListStyle>
      </Space>

      <Space bottom="2rem">
        Мой сайт:{" "}
        <a
          href="https://www.cat-in-web.ru/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Cat-in-web.ru
        </a>
      </Space>

      <Space bottom="2rem">
        Мой Telegram-канал по изучению React:{" "}
        <a
          href="https://t.me/react_junior"
          target="_blank"
          rel="noopener noreferrer"
        >
          React Junior
        </a>
      </Space>

      <div>
        <Space bottom="1rem">
          <h3>Технологии</h3>
        </Space>

        <div>
          {AboutData.skills.map((skillGroup, i) => (
            <TechGroup
              key={i}
              title={skillGroup.title}
              items={skillGroup.items}
            />
          ))}
        </div>
      </div>
    </AboutWrapperStyle>
  );
}

export { About };
