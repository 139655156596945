import styled, { createGlobalStyle } from "styled-components";

import { About } from "./components/About";
import { BigProjects } from "./components/BigProjects";
import { Footer } from "./components/Footer";
import { Form } from "./components/Form";
import { Hero } from "./components/Hero";
import { SmallProjects } from "./components/SmallProjects";
import { COLORS } from "./constants/colors";
import { Container } from "./containers/Container";
import { Screen, ScreenTitle } from "./containers/Screen";

import bg from "./img/furrycat.jpg";
import { useEffect } from "react";
import { FONTS } from "./constants/typography";

interface GradientProps {
  invert?: boolean;
}

const AppStyle = styled.div``;

const PageStyle = styled.div`
  position: relative;
`;

const MainStyle = styled.main``;
const BgStyle = styled.div`
  position: fixed;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url(${bg});
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  filter: grayscale(1);
`;

const GradientStyle = styled.div`
  background: linear-gradient(to bottom, transparent, ${COLORS.screenBg});
  height: 300px;
`;

const FooterScreenStyle = styled(Screen)`
  padding-top: 200px;
  background: linear-gradient(
    to bottom,
    ${COLORS.screenBg},
    ${COLORS.footerBg}
  );
`;

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  html {
    font-size: 16px;
  }
  body {
    font-family: ${FONTS.main};
    color: white;
  }
`;

function App() {
  useEffect(() => {
    window.addEventListener("scroll", () => {});
  }, []);

  return (
    <AppStyle>
      <GlobalStyle />
      <BgStyle />

      <PageStyle>
        <Hero />

        <MainStyle>
          <GradientStyle />
          <Screen>
            <Container>
              <ScreenTitle>Мелкие проекты</ScreenTitle>
              <SmallProjects />
            </Container>
          </Screen>

          <Screen>
            <Container>
              <ScreenTitle>Большие проекты</ScreenTitle>
              <BigProjects />
            </Container>
          </Screen>

          <Screen>
            <Container>
              <ScreenTitle>Обо мне</ScreenTitle>
              <About />
            </Container>
          </Screen>

          <Screen>
            <Container>
              <Form />
            </Container>
          </Screen>
        </MainStyle>

        <FooterScreenStyle>
          <Footer />
        </FooterScreenStyle>
      </PageStyle>
    </AppStyle>
  );
}

export default App;
