import { memo, useState, useCallback, useEffect } from 'react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import styled from 'styled-components';
import { SwiperModule } from 'swiper/types';

type CarouselSlide = {
	img: string;
	text: string;
	alt: string;
};

interface CarouselProps {
	slides: Array<CarouselSlide>;
}

interface SliderProps {
	slides: Array<CarouselSlide>;
	onInit: (swiper: any) => void;
	onSlideChange: () => void;
}

const SlideStyle = styled.div`
	display: flex;
	justify-content: center;
	img {
		max-width: 100%;
	}
`;
const TitleStyle = styled.div`
	text-align: center;
	padding: 0.5rem;
	font-size: 0.875rem;
`;

const Slider = memo(function Slider({
	slides,
	onInit,
	onSlideChange,
}: SliderProps) {
	return (
		<Swiper
			slidesPerView={1}
			modules={[Navigation, Pagination]}
			navigation
			pagination={{ clickable: true }}
			autoHeight
			onSwiper={onInit}
			onSlideChange={onSlideChange}
		>
			{slides.map((slide, i) => (
				<SwiperSlide key={i}>
					<SlideStyle>
						<img src={slide.img} alt={slide.alt} />
					</SlideStyle>
				</SwiperSlide>
			))}
		</Swiper>
	);
});

function Carousel({ slides }: CarouselProps) {
	const [sliderInstance, setSliderInstance] = useState<SwiperCore | null>(
		null
	);
	const [title, setTitle] = useState('');
	const onSliderInit = useCallback(
		(swiper: any) => {
			setSliderInstance(swiper);
		},
		[setSliderInstance]
	);

	useEffect(() => setTitle(slides[0]?.text || ''), [slides]);

	const onSlideChange = useCallback(() => {
		if (sliderInstance) {
			const { activeIndex } = sliderInstance;
			const slide = slides[activeIndex];
			if (slide) {
				setTitle(slide.text || '');
				return;
			}
		}
		setTitle('');
	}, [setTitle, slides, sliderInstance]);
	return (
		<div>
			<Slider
				slides={slides}
				onInit={onSliderInit}
				onSlideChange={onSlideChange}
			/>
			<TitleStyle>{title}</TitleStyle>
		</div>
	);
}

export type { CarouselProps, CarouselSlide };
export { Carousel };
