import { useState } from "react";
import { Space } from "../../containers/Space";
import BigProjectsList from "../../data/big-projects";
import { Tabs } from "../Tabs";
import { ProjectView } from "./ProjectView";

const List = BigProjectsList.filter((project) => project.enabled);
const ProjectsTabs = List.map((project) => ({
  id: project.id,
  text: project.name,
}));

function BigProjects() {
  const [activeProject, setActiveProject] = useState(ProjectsTabs[0].id);

  const projectData = List.find((project) => project.id === activeProject);

  return (
    <div className="tabs big-projects__tabs">
      <Space bottom="2rem">
        <Tabs
          items={ProjectsTabs}
          active={activeProject}
          onChange={setActiveProject}
        />
      </Space>

      {projectData && (
        <div>
          <ProjectView project={projectData} />
        </div>
      )}
    </div>
  );
}

export { BigProjects };
