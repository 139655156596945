import styled from "styled-components";
import { Button } from "../../containers/Button";
import { Project } from "../../types";

import GithubIcon from "../../img/github.png";

import { useLayoutEffect, useState, useRef } from "react";

interface ProjectCardProps {
  project: Project;
}

interface CardStyleProps {
  visible: boolean;
}

const ImgStyle = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0.4);

  transition: filter 400ms ease-in-out;
  img {
    max-width: 100%;
  }
`;
const WrapperStyle = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  text-align: center;
  opacity: 0;
  transition: opacity 400ms;
`;
const ContentStyle = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  backdrop-filter: blur(1px);
`;
const TitleStyle = styled.div`
  font-size: 1.3em;
  font-weight: 700;
`;
const DescriptionStyle = styled.div`
  margin-top: 10px;
`;
const KeywordsStyle = styled.div`
  font-size: 0.75em;
  margin-top: 0.5em;
  span:not(:last-child):after {
    content: ", ";
  }
`;
const FooterStyle = styled.footer`
  display: flex;
  margin-top: 1rem;
`;

const GithubLinkStyle = styled(Button)`
  width: 40px;
  padding: 0;
  flex-shrink: 0;
  background-color: rgba(0, 0, 0, 0.5);
  background-image: url(${GithubIcon});
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 10px;

  &:hover {
    background-color: rgba(0, 0, 0, 1);
  }
`;
const LinkStyle = styled(Button)`
  flex-grow: 1;
`;

const CardStyle = styled.article<CardStyleProps>`
  position: relative;
  border-radius: 40% / 10%;
  overflow: hidden;
  transition: border-radius 400ms, filter 400ms ease-in-out;
  cursor: pointer;
  box-shadow: 0px 0px 20px rgba(255, 255, 255, 0.25);
  filter: grayscale(${(props) => (props.visible ? 0 : 1)});

  &:before {
    content: "";
    display: block;
    width: 100%;
    padding-top: 100%;
  }

  &:hover {
    border-radius: 0;

    ${ImgStyle} {
      filter: brightness(0.4);
    }

    ${WrapperStyle} {
      opacity: 1;
    }
  }
`;

function ProjectCard({ project }: ProjectCardProps) {
  const observer = useRef<HTMLDivElement | null>(null);
  const [visible, setVisible] = useState(false);

  useLayoutEffect(() => {
    if (!observer.current) return;
    const mutationObserver = new IntersectionObserver(([e]) => {
      setVisible(e.isIntersecting);
    });
    mutationObserver.observe(observer.current);
  }, []);

  const { id, link, name, description, keywords, github } = project;
  const img = `/assets/screenshots/${id}.png`;

  return (
    <CardStyle visible={visible}>
      <ImgStyle>
        <img src={img} alt={name} />
      </ImgStyle>
      <WrapperStyle>
        <ContentStyle>
          <TitleStyle>{name}</TitleStyle>
          {description && <DescriptionStyle>{description}</DescriptionStyle>}
          {keywords.length > 0 && (
            <KeywordsStyle>
              {keywords.map((word) => (
                <span key={word}>#{word}</span>
              ))}
            </KeywordsStyle>
          )}
        </ContentStyle>
        <FooterStyle>
          {github && (
            <GithubLinkStyle
              href={github}
              title="Смотреть на GitHub"
              rel="noopener noreferrer"
              target="_blank"
            ></GithubLinkStyle>
          )}
          <LinkStyle href={link} rel="noopener noreferrer" target="_blank">
            Посмотреть
          </LinkStyle>
        </FooterStyle>
      </WrapperStyle>

      <div ref={observer}></div>
    </CardStyle>
  );
}

export type { ProjectCardProps };
export { ProjectCard };
