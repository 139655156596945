import React, { useCallback,  useRef, useState } from "react";
import styled from 'styled-components'

import { Button } from "../../containers/Button";
import { FeedbackMessage } from "./Feedback";

interface FileLoaderProps {
  accept?: string;
  files: Array<File>;
  max?: number;
  maxSize?: number;
  onChange: (files: Array<File>) => void;
}

interface FilePreviewProps {
  file: File;
  onRemove: () => void;
}

const ButtonStyle = styled(Button)``

const PreviewStyle = styled.div`
  display: flex;
  align-items: center;
  margin: 1rem 0;
`

const RemoveButtonStyle = styled(Button)`
  margin-left: 1rem;
  width: 25px;
  height: 25px;
  padding: 0;
`

let unique = 1;

function FilePreview({ file, onRemove }: FilePreviewProps) {
  return (
    <PreviewStyle>
      <div>{file.name}</div>
      <RemoveButtonStyle as="button" type="button" onClick={onRemove}>
        &times;
      </RemoveButtonStyle>
    </PreviewStyle>
  );
}

function FileLoader({
  accept,
  max,
  maxSize,
  files,
  onChange,
}: FileLoaderProps) {
  const [filesMap, setFilesMap] = useState(() => {
    const map = new Map<File, number>();
    files.forEach((file) => map.set(file, unique++));
    return map;
  });
  const [error, setError] = useState('');
  const InputRef = useRef<HTMLInputElement | null>(null);

  const selectFiles = useCallback(() => {
    setError('');
    if (!InputRef.current) return;
    InputRef.current.click();
  }, [InputRef]);

  const loadFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError('');
    const newFiles = e.target.files;

    let list = newFiles ? Array.from(newFiles) : [];
    if (maxSize) {
      let prevCount = list.length;
      list = list.filter((file) => file.size <= maxSize);
      if (list.length < prevCount) setError('Файл слишком большой');
    }
    setFilesMap((prev) => {
      list.forEach((file) => {
        prev.set(file, unique++);
      });
      return prev;
    });
    let newList = [...files, ...list];
    if (max) {
      newList = newList.slice(0, max);
    }
    onChange(newList);

    setTimeout(() => (e.target.value = ""));
  };

  const removeFile = (file: File) => {
    setError('');
    const list = files.filter((f) => f !== file);
    setFilesMap((prev) => {
      prev.delete(file);
      return prev;
    });
    onChange(list);
  };

  return (
    <div>
      <div>
        {files.map((file) => (
          <FilePreview
            key={filesMap.get(file)}
            file={file}
            onRemove={() => removeFile(file)}
          ></FilePreview>
        ))}
      </div>

      <ButtonStyle as="button" type="button" onClick={selectFiles}>
        Выбрать файлы
      </ButtonStyle>


      <input
        ref={InputRef}
        accept={accept}
        type="file"
        multiple
        hidden
        onChange={loadFiles}
      />

      {error && <FeedbackMessage>{error}</FeedbackMessage>}
    </div>
  );
}

export type { FileLoaderProps };
export { FileLoader };
