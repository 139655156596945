import styled from 'styled-components';

interface SpaceProps {
	margin?: boolean;
	top?: string;
	left?: string;
	right?: string;
	bottom?: string;
	x?: string;
	y?: string;
	all?: string;
}

const Space = styled.div<SpaceProps>`
	${(props) =>
		props.margin
			? `margin-left: ${props.left || props.x || props.all || 0};
				margin-right: ${props.right || props.x || props.all || 0};
				margin-top: ${props.top || props.y || props.all || 0};
				margin-bottom: ${props.bottom || props.y || props.all || 0};`
			: `padding-left: ${props.left || props.x || props.all || 0};
				padding-right: ${props.right || props.x || props.all || 0};
				padding-top: ${props.top || props.y || props.all || 0};
				padding-bottom: ${props.bottom || props.y || props.all || 0};`}`;

export { Space };
