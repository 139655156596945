import styled from 'styled-components';

interface ButtonProps {
	lg?: boolean;
}

const Button = styled.a<ButtonProps>`
	height: ${props => props.lg ? 52 : 40}px;
	text-decoration: none;
	border-radius: 4px;
  box-shadow: none;
  border: none;
  outline: none;
	line-height: 1;
	font-weight: 700;
	display: inline-flex;
	padding: 0.5rem ${props => props.lg ? 1.5 : 1}rem;
	font-size: ${props => props.lg ? 1 : 0.875}rem;
	align-items: center;
	justify-content: center;
	background: white;
	color: #333;
	transition: background-color 250ms ease-in-out;
	cursor: pointer;

  &:focus, &:active, &:hover {
    outline: none;
    border: none;
    box-shadow: none;
  }
`;

export { Button };
