import { Project, ProjectTypes } from "../types";

const projects: Array<Project> = [
  {
    enabled: true,
    type: ProjectTypes.Landing,
    id: "pratchett",
    name: "Плоский мир",
    description: "Путеводитель по книгам Терри Пратчетта",
    adaptive: false,
    interactive: true,
    keywords: ["animation", "svg", "slider"],
    year: 2017,
    company: "chitai-gorod",
    technologies: ["html", "css", "svg", "javascript"],
    code: false,
    link: "https://mohnatus.github.io/pratchett/",
    github: "https://github.com/mohnatus/pratchett",
    rating: 4,
  },
  {
    enabled: true,
    type: ProjectTypes.Landing,
    id: "agents",
    name: "Читай-агенты",
    description: "Посадочная страница для акции",
    adaptive: true,
    interactive: false,
    keywords: ["animation", "svg", "form", "frame"],
    year: 2018,
    company: "chitai-gorod",
    technologies: ["html", "css", "svg", "javascript"],
    code: false,
    link: "https://mohnatus.github.io/chitai-agents/",
    rating: 3,
  },
  {
    enabled: true,
    type: ProjectTypes.Landing,
    id: "let-it-snow",
    name: "Let it snow",
    description: "Новогодняя открытка",
    adaptive: true,
    interactive: false,
    keywords: ["animation"],
    year: 2018,
    company: "chitai-gorod",
    technologies: ["html", "css", "javascript"],
    code: false,
    link: "https://mohnatus.github.io/let-it-snow/",
    rating: 2,
  },
  {
    enabled: true,
    type: ProjectTypes.Interactive,
    id: "chg-test",
    name: "Какой ты читатель?",
    description: "Тест на знание литературы",
    adaptive: false,
    keywords: [],
    year: 2017,
    company: "chitai-gorod",
    technologies: ["html", "css", "javascript"],
    code: false,
    link: "https://mohnatus.github.io/party-test/",
    rating: 4,
  },
  {
    enabled: true,
    type: ProjectTypes.Interactive,
    id: "boxes",
    name: "Конструктор коробочек",
    description: "Конструктор подарочных коробок",
    adaptive: false,
    keywords: ["slider", "svg"],
    year: 2018,
    company: "chitai-gorod",
    technologies: ["html", "css", "javascript", "svg"],
    code: true,
    link: "https://portfolio.furrycat.ru/projects/boxes/",
    rating: 6,
  },
  {
    enabled: true,
    type: ProjectTypes.Interactive,
    id: "filword-client",
    name: "Филворд",
    description: "Игра Филворд",
    adaptive: true,
    keywords: ["svg"],
    year: 2018,
    company: "chitai-gorod",
    technologies: ["html", "css", "javascript", "svg"],
    code: false,
    link: "https://mohnatus.github.io/filword-client/",
    github: "https://github.com/mohnatus/fillword",
    rating: 7,
  },
  {
    enabled: true,
    type: ProjectTypes.Interactive,
    id: "snowman",
    name: "Наряди снеговика",
    description: "Интерактивный фрагмент игры",
    adaptive: false,
    keywords: ["animation"],
    year: 2018,
    company: "chitai-gorod",
    technologies: ["html", "css", "javascript"],
    code: false,
    link: "https://mohnatus.github.io/snowman/",
    rating: 3,
  },
  {
    enabled: true,
    type: ProjectTypes.Interactive,
    id: "filword-admin",
    name: "Конструктор филворда",
    description: "Создание головоломки-филворда из набора слов",
    adaptive: false,
    keywords: ["react"],
    year: 2018,
    company: "chitai-gorod",
    technologies: ["html", "css", "javascript", "react"],
    code: false,
    link: "https://mohnatus.github.io/filword-admin/",
    github: "https://github.com/mohnatus/wordSearch",
    rating: 5,
  },
  {
    enabled: true,
    type: ProjectTypes.Landing,
    id: "express-native",
    name: "Express-native",
    description: "Рекламная посадочная страница",
    adaptive: true,
    keywords: ["form", "slider", "svg"],
    year: 2019,
    company: "proglib",
    technologies: ["html", "css", "javascript", "scss"],
    code: false,
    link: "https://mohnatus.github.io/express-native/",
    github: "https://github.com/mohnatus/express-native",
    rating: 5,
  },
  {
    enabled: true,
    type: ProjectTypes.Interactive,
    id: "css-filters",
    name: "CSS Filters Demo",
    description: "Демонстрация работы CSS фильтров",
    adaptive: true,
    keywords: ["react", "redux", "animation", "material design"],
    year: 2020,
    technologies: ["react", "material ui", "redux"],
    code: false,
    link: "http://css.cat-in-web.ru/filters/",
    github: "https://github.com/mohnatus/css-filters",
    rating: 8,
  },
  {
    enabled: true,
    type: ProjectTypes.Interactive,
    id: "blast",
    name: "Blast Game",
    description: "Головоломка с механикой Blast",
    adaptive: false,
    keywords: ["vue", "canvas", "animation"],
    year: 2019,
    technologies: ["vue", "sass", "canvas"],
    code: false,
    link: "https://mohnatus.github.io/blast-game/",
    github: "https://github.com/mohnatus/blast.game",
    rating: 9,
  },
  {
    enabled: true,
    type: ProjectTypes.Interactive,
    id: "candlestick",
    name: "BTC/USDT Price Chart",
    description: "Свечной график изменения цены BTC/USDT",
    adaptive: true,
    keywords: ["react", "redux", "typescript", "api"],
    year: 2019,
    technologies: ["react", "redux", "scss", "api", "typescript"],
    code: false,
    link: "https://mohnatus.github.io/candlestick-chart/index.html",
    github: "https://github.com/mohnatus/candlestick-chart",
    rating: 5,
  },
];

export default projects;
