const data = {
  start: {
    year: 2016,
    month: 9,
  },
  skills: [
    {
      title: "Верстка",
      items: [
        "HTML5 + CSS3",
        "Шаблонизаторы: Pug, Twig",
        "Адаптивная/Резиновая верстка",
        "Кроссбраузерность",
        "Валидная разметка",
        "Доступность, ARIA",
        "Препроцессор SCSS, PostCSS",
        "Styled Components",
        "Порядок в стилях - stylelint",
        "Оптимизация",
        "Bootstrap/Foundation",
      ],
    },
    {
      title: "JavaScript",
      items: [
        "ES6+",
        "TypeScript",
        "React",
        "Redux",
        "Next.js",
        "Vue",
        "API",
        "Тестирование - Jest",
        "Порядок в коде - ESLint",
        "jQuery, если нужно",
      ],
    },
    {
      title: "Анимация",
      items: [
        "CSS и JS анимация",
        "Canvas",
        "Svg",
        "Библиотеки анимаций",
        "Производительные анимации",
      ],
    },
    {
      title: "Графические редакторы",
      items: ["Photoshop", "Figma", "Zeplin", "Adobe XD"],
    },
    {
      title: "Инструменты",
      items: ["Webpack/Parcel", "Babel", "Линтеры кода"],
    },
    {
      title: "Backend",
      items: ["AJAX", "REST", "PHP - базовые знания", "CMS WordPress"],
    },
  ],
};

export default data;
