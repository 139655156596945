import styled, { keyframes } from 'styled-components'

interface SpinnerProps {
  size?: number
}

const rotate = keyframes`
 from {
  transfrom: rotate(0deg);
 }
 to {
  transform: rotate(360deg)
 }
`

const Spinner = styled.div<SpinnerProps>`
  width: ${props => props.size || 25}px;
  height: ${props => props.size || 25}px;
  border-radius: 50%;
  border: 4px solid currentColor;
  border-top-color: transparent;

  animation: ${rotate} 1.5s linear infinite;
`

export { Spinner }