import styled from "styled-components";
import { DEVICE } from "../../constants/breakpoints";
import { COLORS } from "../../constants/colors";


const Screen = styled.div`
  padding: 5rem 0;
  background: ${COLORS.screenBg};
  @media ${DEVICE.mobileLgDown} {
    padding: 3rem 0;
  }
`;

const ScreenTitle = styled.h2`
  margin-bottom: 2rem;
  font-size: 2rem;
  font-weight: 700;
  text-transform: uppercase;

  @media ${DEVICE.mobileLgDown} {
    font-size: 1.5rem;
    text-align: center;
  }
`;



export { Screen, ScreenTitle };
