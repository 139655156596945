export const BREAKPOINTS = {
  smallMobileTop: 369.98,
  mediumMobileTop: 609.98,
  largeMobileTop: 767.98,


}

export const DEVICE = {
  mobileSmDown: `(max-width: ${BREAKPOINTS.smallMobileTop}px)`,
  mobileMdDown: `(max-width: ${BREAKPOINTS.mediumMobileTop}px)`,
  mobileLgDown: `(max-width: ${BREAKPOINTS.largeMobileTop}px)`,
}